import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import BlogCard from '../components/BlogContent/BlogCard'
import { graphql } from 'gatsby'

const Blog = ({data}) => {
  const articles = data.allStrapiNewblog.edges;

  return (
      <Layout>
          <Navbar />
          {/*<PageBanner
              pageTitle="Blog"
              homePageText="Home"
              homePageUrl="/"
              activePageText="Blog"
          />*/}
          <BlogCard articles={articles}/>
          <Footer />
      </Layout>
  );
}

export default Blog;

export const pageQuery = graphql`
  query {
    allStrapiNewblog(sort: { fields: Date, order: DESC }) {
      edges {
        node {
          Markdown
          Outspoke
          Slug
          Author
          Date
          Published
          Snippet
          Title
          id
          MainImage {
            url
            localFile {
              publicURL
            }
          }
          Date
        }
      }
    }
  }
`
