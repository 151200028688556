export default function convertDateOnly(date) {

    let now_date = new Date(date)
    let now_date_month = now_date.getMonth()+1
    let now_date_day = now_date.getDate()
    let now_date_year = now_date.getFullYear()

    let newDate = now_date_month+'/'+now_date_day+'/'+now_date_year
    return newDate

}
